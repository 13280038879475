<template>
  <div :class="$style.statusWrap" class="h-full flex flex-col items-center">
    <div class="w-full flex items-center" :class="$style.wrapHeader">
      <a-select
        :options="deviceOptions"
        v-model="deviceId"
        @change="handleChange"
        style="width: 1.8rem;"
      ></a-select>
      <div class="flex">
        <span :class="[$style.endTime]">最后更新时间：{{ lastTime }}</span>
      </div>
      <span :class="$style.deviceId">设备码：{{ deviceCode }}</span>
    </div>
    <img
      :class="$style.unloadImg"
      src="@/assets/images/screen/unloading-img.svg"
    />

    <div
      class="grid grid-cols-5 gap-x-rem-16 gap-y-rem-16"
      :class="$style.indicatorWrap"
    >
      <div
        :class="$style.indicator"
        class="flex justify-between items-center"
        v-for="(item, index) in indicator"
        :key="index"
      >
        <div :class="$style.leftLabel">
          <AliIcon :type="item.icon" :class="$style.iconStyle" />
          <div :class="[$style.text]">{{ item.text }}</div>
        </div>
        <div :class="[$style.textValue]" v-if="item.unit">
          <span>{{ item.count ?? '-' }}</span>
          <span :class="[$style.unit]">{{ item.unit }}</span>
        </div>
        <div :class="[$style.textStatus]" v-else>
          <span>{{
            item.format ? item.format(item.count) : item.count ?? '-'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import { deviceStatus } from '@/services/screen/jiangsu/common';
import { Emitter } from '@/views/screen/components/screen-utils';
import moment from 'moment';

@Component({ components: { AliIcon } })
export default class UnloadStatus extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.unloadMonitor].deviceGroup;

  indicator = {
    weight: {
      text: '料台重量',
      icon: 'tc-icon-zaizhongliang',
      count: null,
      unit: 't',
    },
    height: {
      text: this.$t('detail.height'),
      icon: 'tc-icon-gaodu',
      count: null,
      unit: 'm',
    },
    speed: {
      text: '速度',
      icon: 'tc-icon-yunhangsudu',
      count: null,
      unit: 'm/s',
    },
    dip: {
      text: '倾角',
      icon: 'tc-icon-qingjiao',
      count: null,
      format: text => `${text > 0 ? text : '-'}°`,
    },
    weightStatus: {
      text: '重量状态',
      icon: 'tc-icon-zaizhongliang',
      count: null,
      format: text => ['-', '正常', '预警', '报警'][text] ?? '-',
    },
    speedStatus: {
      text: '速度状态',
      icon: 'tc-icon-yunhangsudu',
      count: null,
      format: text => ['-', '正常', '预警', '报警'][text] ?? '-',
    },
    dipStatus: {
      text: '倾角状态',
      icon: 'tc-icon-qingjiao',
      count: null,
      format: text => ['-', '正常', '预警', '报警'][text] ?? '-',
    },
  };

  get projectId() {
    return this.$store.state.screen.projectId;
  }

  // 设备
  deviceId = '';
  deviceOptions = [];
  deviceData = [];
  deviceStatusData = {};
  get deviceCode() {
    return (
      this.deviceData.find(e => this.deviceId === e.pkId)?.deviceCode ?? '-'
    );
  }
  get lastTime() {
    const lastDataTime = this.deviceStatusData.lastUpdateTime;
    return lastDataTime
      ? moment(lastDataTime).format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }
  async getDeviceLists() {
    const res = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
    this.deviceData = res;
    this.deviceOptions = res.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
    const [{ value }] = this.deviceOptions;
    this.deviceId = value;
    Emitter.$emit('deviceChange', this.deviceId);
  }
  handleChange() {
    Emitter.$emit('deviceChange', this.deviceId);
  }

  // indicator
  async getIndicator() {
    const res = (await deviceStatus(this.deviceId, 'other/up')) ?? {};
    this.deviceStatusData = res;
    for (const key in this.indicator) {
      if (Object.hasOwnProperty.call(this.indicator, key)) {
        this.indicator[key].count = +res[key] > 0 ? res[key] : '-';
      }
    }
  }

  deviceChange() {
    this.getIndicator();
  }

  created() {
    Emitter.$on('deviceChange', this.deviceChange);
  }
  async mounted() {
    await this.getDeviceLists();
  }
  beforeDestroy() {
    Emitter.$off('deviceChange', this.deviceChange);
  }
}
</script>

<style lang="less" module>
@import '@/assets/css/mixin.less';
.statusWrap {
  .px2rem(padding, 16);
  position: relative;
  user-select: none;
  .unloadImg {
    .px2rem(height, 400);
    .px2rem(width, 800);
    margin-top: 0.4rem;
  }
  .floatBox {
    position: absolute;
    right: 0;
    top: 0.72rem;
    div {
      writing-mode: vertical-lr;
      letter-spacing: 5px;
      padding: 0.13rem 0.08rem 0.08rem 0.08rem;
      background: #223142;
      cursor: pointer;
      border-radius: 0.08rem 0 0 0.08rem;
      font-size: 0.12rem;
    }
  }
}
.indicator {
  width: 1.5rem;
  aspect-ratio: 143/ 56;
  padding: 0.08rem;
  background: url('~@/assets/images/theme/card2-bg.svg') center / 100% 100%;
}
.indicatorWrap {
  margin-top: 0.38rem;
}
.timeText {
  margin-top: 0.18rem;
  color: #9ac0d9ff;
  font-size: 0.12rem;
}
.wrapHeader {
  // margin-bottom: auto;
  height: 0.32rem;
}
.textValue,
.textStatus {
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  font-size: 0.16rem;
  color: #fff;
  word-break: keep-all;
}

.textValue {
  font-size: 0.24rem;
  .unit {
    font-size: 0.12rem;
  }
}
.iconStyle {
  font-size: 0.24rem;
  color: #fff;
}
.leftLabel {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #9ac0d9;
  .text {
    width: 0.36rem;
    line-height: 1.2;
    letter-spacing: 0.03rem;
    margin-left: 0.1rem;
  }
}

.deviceId {
  color: #9ac0d9;
  font-size: 0.14rem;
  margin-left: auto;
}
.endTime {
  color: #d8e1f3;
  font-size: 0.12rem;
  margin-left: 0.16rem;
}
</style>
