import request from '../../request';

const serviceName = '/smart-site/screen';

// 获取关联的摄像头设备
export function getMonitoringList(body) {
  return request(`${serviceName}/getMonitorDeviceList`, {
    method: 'GET',
    body,
  });
}

// 获取项目的塔吊设备报警信息
export function getCurrentAlarmInfos(projectId, deviceId) {
  return request(
    `${serviceName}/tower/getCurrentAlarmInfos?projectId=${projectId}&deviceId=${deviceId}`,
    {
      method: 'GET',
    },
  );
}

// 塔吊司机上下机记录-弹窗列表
/**
 *
 * @param {*} body
 * @param {*} body.current
 * @param {*} body.size
 * @param {*} body.idxProjectId
 * @param {'elevator' | 'tower'} path
 * @returns
 */
export function getUpDownLog(body, path) {
  return request(`${serviceName}/${path}/onBoard/page`, {
    method: 'POST',
    body,
  });
}

/** 塔吊设备运行时长 */
export function getTowerRuningTime(body) {
  return request(`${serviceName}/tower/runningTime/statistics`, {
    method: 'GET',
    body,
  });
}

/** 塔吊司机信息 */
export function getTowerDriverDetail(deviceId) {
  return request(`${serviceName}/tower/driver/detail?deviceId=${deviceId}`, {
    method: 'GET',
  });
}

/** 设备信息详情 */
export function getTowerDeviceDetail(deviceId) {
  return request(`${serviceName}/tower/device/detail?deviceId=${deviceId}`, {
    method: 'GET',
  });
}

/** 升降机饼图 */
export function getLiftAlarmStatics(body) {
  return request(`${serviceName}/elevator/alarm/alarmStaticsByElevator`, {
    method: 'POST',
    body,
  });
}

/** 升降机运行时长分析 */
/**
 * @param {object} body
 * @param {*} body.days 日期筛选
 * @param {*} body.projectId 项目ID
 * @param {*} body.deviceId 设备ID
 */
export function liftRuningTimeStatics(body) {
  return request(`${serviceName}/elevator/runningTime/statistics`, {
    method: 'GET',
    body,
  });
}

/**
 * 获取设备状态
 * @param { string } deviceId
 * @param { 'up' | 'elevator' | 'tower' | 'other/up' } path
 * @description up:升降机  tower:塔吊  other/up:卸料平台
 */
export function deviceStatus(deviceId, path) {
  return request(`${serviceName}/${path}/status/${deviceId}`, {
    method: 'GET',
  });
}

/**
 * 卸料平台报警趋势
 * @param {object} body
 * @param {'SEVEN_DAY' | 'THIRTY_DAY'} body.days
 * @param {string} body.projectId
 * @returns
 */
export function unloadAlertTrends(body) {
  return request(`${serviceName}/other/up/alarm/stat/trend/count`, {
    method: 'GET',
    body,
  });
}

/**
 * 获取警报分页列表
 * @param {object} body
 * @param {{current: number, size: number}} body.query // 分页
 * @param {number | string} body.projectId // 项目ID
 * @param {'WARN' | 'ERROR'} body.type // 报警|预警
 * @param {'WARN' | 'ERROR'} body.deviceId // 设备ID
 * @returns
 */
export function unloadAlertList(body) {
  return request(`${serviceName}/other/up/alarm/record/list`, {
    method: 'POST',
    body,
  });
}

// smart-site/screen/elevator/record/page
/**
 * 升降机运行记录列表
 * @param {object} body
 * @param {number} body.current
 * @param {numebr} body.size
 * @param {string} body.idxProjectId
 * @param {string} body.deviceId 设备ID
 * @returns
 */
export function runingLog(body) {
  return request(`${serviceName}/elevator/record/page`, {
    method: 'GET',
    body,
  });
}

/**
 * 获取设备信息
 * @param {object} body
 * @param {string} body.deviceGroup
 * @param {string} body.projectId
 * @returns
 */
export function commonDeviceList(body) {
  return request(`/triascloud-smart-site/jiangsu/screen/common/device`, {
    method: 'GET',
    body,
  });
}
