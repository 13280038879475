<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <a-select
        placeholder="请选择设备"
        :options="deviceOption"
        v-model="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
      ></a-select>
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { nowSize } from '@/utils/common';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';
import { unloadAlertList } from '@/services/screen/jiangsu/common';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class TableModal extends Vue {
  @Prop({ required: true }) projectId;
  @Prop({ required: true }) deviceOption;
  @Prop({ required: true, type: String }) type;

  params = {
    deviceId: undefined,
  };
  async api({ current, size }) {
    const res = await unloadAlertList({
      query: {
        current,
        size,
      },
      projectId: this.projectId,
      type: this.type,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(200),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(200),
    },
    {
      title: '报警信息',
      dataIndex: 'reason',
      width: nowSize(100),
    },
    {
      title: '触发阈值',
      width: nowSize(100),
      dataIndex: 'threshold',
    },
    {
      title: '触发数值',
      dataIndex: 'alarmValue',
      width: nowSize(100),
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      width: nowSize(200),
      customRender: text =>
        +text > 0 ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
  ];

  selectChange() {
    this.$refs.tableRef.search();
  }
}
</script>

<style lang="less" module></style>
