<template>
  <div
    :class="['grid', 'grid-cols-2', 'gap-rem-16', 'overflow-hidden', 'h-full']"
  >
    <LayoutCard :title="$t('safety.deviceStatus')">
      <DeviceStatus ref="deviceStatusRef" />
    </LayoutCard>
    <div class="flex flex-col gap-rem-16 overflow-hidden">
      <div>
        <LayoutCard title="卸料平台平面图">
          <template #action>
            <ali-icon
              type="tc-icon-zhankai"
              @click="handleFullScreen"
            ></ali-icon>
          </template>
          <div :class="$style.planViewWrap">
            <PlanViewCanvas></PlanViewCanvas>
          </div>
        </LayoutCard>
      </div>

      <div class="flex-1 grid grid-cols-2 gap-rem-16 overflow-hidden">
        <LayoutCard title="预警/报警趋势">
          <template #action>
            <range-picker
              @ok="v => handleSearchTime(v, '1')"
              v-if="alertTrendsSelectValue === 'CUSTOM_DAY'"
            />
            <a-select
              :options="alertTrendsSelectOption"
              v-model="alertTrendsSelectValue"
              @change="alertTrendsSelectChange"
            ></a-select>
          </template>
          <div class="h-full" style="padding: 0.16rem;">
            <LineChart
              :isSmooth="false"
              symbol="round"
              chartRight="2%"
              :chart-data="alertTrendsChartOption"
              :show-legend="true"
            />
          </div>
        </LayoutCard>

        <LayoutCard :title="['预警', '报警数据']">
          <ScrollTable
            :columns="scrollTbaleColumns"
            :data="alertListApiWarn"
            @cellClick="handleCellClick('WARN')"
            infiniteScroll
          ></ScrollTable>

          <ScrollTable
            :columns="scrollTbaleColumns"
            :data="alertListApiError"
            @cellClick="handleCellClick('ERROR')"
            infiniteScroll
          ></ScrollTable>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard, LayoutSelect } from '../../../layout';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import DeviceStatus from './components/unload-status.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import PlanViewCanvas from './components/canvas';
import { createModal } from '@triascloud/x-components';
import {
  unloadAlertTrends,
  unloadAlertList,
} from '@/services/screen/jiangsu/common';
import moment from 'moment';
import AliIcon from '@/components/ali-icon/index.vue';
import TableModal from './components/table-modal.vue';
import RangePicker from '@/views/screen/components/range-picker.vue';

@Component({
  components: {
    LayoutCard,
    LayoutSelect,
    ScrollTable,
    DeviceStatus,
    LineChart,
    PlanViewCanvas,
    AliIcon,
    RangePicker,
  },
})
export default class UnloadIndex extends Vue {
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  colorArray = ['#6BBDFF'];
  scrollTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      width: '25%',
    },
    {
      title: '报警内容',
      key: 'reason',
      width: '20%',
    },
    {
      title: '报警数值',
      key: 'alarmValue',
      width: '20%',
    },
    {
      title: '报警时间',
      key: 'alarmTime',
      width: '',
      format: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  // 预警/报警趋势
  alertTrendsSelectOption = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  alertTrendsData = {
    dataList: [],
    times: [],
  };
  alertTrendsSelectValue = 'SEVEN_DAY';
  get alertTrendsChartOption() {
    const { times, dataList } = this.alertTrendsData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
            unit: '次',
          })),
        })),
      },
    ];
  }
  async getUnloadAlertTrends(startTime = undefined, endTime = undefined) {
    this.alertTrendsData = await unloadAlertTrends({
      days: this.alertTrendsSelectValue,
      projectId: this.projectId,
      startTime,
      endTime,
    });
  }
  alertTrendsSelectChange() {
    this.alertTrendsSelectValue !== 'CUSTOM_DAY' && this.getUnloadAlertTrends();
  }

  // 报警分页列表
  async alertListApi(type) {
    const { records } = await unloadAlertList({
      query: {
        size: 15,
        current: 1,
      },
      type,
      projectId: this.projectId,
    });
    return records;
  }
  alertListApiWarn() {
    return this.alertListApi('WARN');
  }
  alertListApiError() {
    return this.alertListApi('ERROR');
  }
  handleCellClick(type) {
    const title = { WARN: '预警', ERROR: '报警' }[type];
    createModal(
      () => (
        <TableModal
          type={type}
          deviceOption={this.$refs.deviceStatusRef.deviceOptions}
          projectId={this.projectId}
        />
      ),
      {
        title: `设备${title}信息`,
        className: 'screen-modal',
        width: '80vw',
      },
    );
  }

  // 时间筛选
  handleSearchTime(time, type) {
    const startTime = this.$moment(time[0]).valueOf();
    const endTime = this.$moment(time[1]).valueOf();
    if (type === '1') {
      this.getUnloadAlertTrends(startTime, endTime);
    }
  }

  handleFullScreen() {
    createModal(
      () => (
        <div
          class={'w-full flex justify-center items-center'}
          style={'aspect-ratio: 16/9;'}
        >
          <PlanViewCanvas isModal />
        </div>
      ),
      {
        width: '80vw',
        wrapClassName: 'konva-wrapper',
      },
    );
  }

  created() {
    this.getUnloadAlertTrends();
  }
}
</script>

<style lang="less" module>
.planViewWrap {
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
